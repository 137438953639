import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { Auth } from "aws-amplify";
import "./style.scss";

export default class TenantLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      tenant_id: "",
      username: "",
      email: "",
      password: ""
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      const user = await Auth.signIn(this.state.email.replace("@", "-at-"), this.state.password);
      this.setState({ user: user })
      this.setState({ challengeName: user.challengeName })

      if (this.state.challengeName === null || this.state.challengeName === undefined) {
        this.props.userHasAuthenticated(true);
      }
    } catch (e) {
      alert(e.message);
    }
    this.setState({ isLoading: false });
  }

  render() {
    return (
      <div className="Login">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="email" bsSize="large">
            <ControlLabel>Email</ControlLabel>
            <FormControl
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>Password</ControlLabel>
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          <Link to="/login/reset">Forgot password?</Link>
          <LoaderButton
            block
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Login"
            loadingText="Logging in…"
          />
          <Link to="/login">Login with your account ID here...</Link>
        </form>
      </div>
    );
  }
}