import React, { useEffect, useState, useRef } from "react";
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  ButtonGroup,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
// aws
import { API } from "aws-amplify";
// jsonschema-form
import { withTheme } from "react-jsonschema-form";
import { Theme as MuiTheme } from "rjsf-material-ui";

const Form = withTheme(MuiTheme);

const useStyles = makeStyles(() => ({
  progress: {
    display: "block",
    margin: "10px auto"
  },
  successFailureRoot: {
    textAlign: "center"
  },
  successFailureIcon: { verticalAlign: "middle", marginRight: "10px" },
  formControlRoot: { width: "100%" },
  formPaper: { marginTop: "25px", padding: "15px" },
  dialogRoot: { minWidth: "500px" }
}));

export default function EndpointFormDialog(props) {
  const [loading, setLoading] = useState(false);
  const [types, setTypes] = useState([]);
  const [selectedTypeId, setSelectedTypeId] = useState("");
  const [schema, setSchema] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState(null);

  const classes = useStyles();

  let submitButton = useRef(null);

  // get endpoint types on form open
  useEffect(() => {
    // reset form and get available types
    if (props.open) {
      resetForm();
      updateEndpointTypes();
    }
    // if editing an endpoint, get it's data and schema
    if (props.currentEndpoint) {
      updateFormSchema(props.currentEndpoint.endpointType);
      getCurrentEndpointData();
    }
  }, [props.open, props.currentEndpoint]);

  // get json form on selecting type
  useEffect(() => {
    if (selectedTypeId && selectedTypeId.length > 0) {
      updateFormSchema();
    }
  }, [selectedTypeId]);

  async function deleteEndpoint() {
    setLoading(true);
    try {
      const delete_res = await API.del(
        "config",
        `/endpoint/${props.currentEndpoint.id}`
      );
      if (delete_res.message) {
        throw delete_res;
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setSubmitted(true);
      setLoading(false);
      props.populateTable();
    }
  }

  async function getCurrentEndpointData() {
    setLoading(true);
    try {
      const currentEndpointData = await API.get(
        "config",
        `/endpoint/${props.currentEndpoint.id}`
      );
      setFormData(currentEndpointData);
    } catch (e) {
      alert(e);
    } finally {
      setLoading(false);
    }
  }

  async function updateFormSchema(endpointType) {
    setLoading(true);
    try {
      let schema_res;
      if (selectedTypeId.length > 0) {
        schema_res = await API.get("config", `/prototype/${selectedTypeId}`);
      } else if (endpointType) {
        const schemaTemp = await API.get("config", "/prototype", {
          queryStringParameters: {
            type: `endpoint|${endpointType}`,
            field: "schema"
          }
        });
        schema_res = schemaTemp.prototypes[0];
      }
      setSchema(schema_res);
    } catch (e) {
      alert(e);
    } finally {
      setLoading(false);
    }
  }

  async function updateEndpointTypes() {
    setLoading(true);
    try {
      const types_res = await API.get("config", "/prototype", {
        queryStringParameters: {
          type: "endpoint"
        }
      });
      setTypes(types_res.prototypes);
    } catch (e) {
      alert(e);
    } finally {
      setLoading(false);
    }
  }

  function handleChangeType(event) {
    setSelectedTypeId(event.target.value);
  }

  async function submitForm({ formData }, e) {
    e.preventDefault();
    setLoading(true);

    let endpoint_res;
    if (!props.currentEndpoint) {
      endpoint_res = await API.post("config", "/endpoint", {
        body: formData
      });
    } else {
      endpoint_res = await API.put(
        "config",
        `/endpoint/${props.currentEndpoint.id}`,
        {
          body: formData
        }
      );
    }

    try {
      if (endpoint_res.message) {
        throw endpoint_res.message;
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setSubmitted(true);
      setLoading(false);
      props.populateTable();
    }
  }

  function showSuccessOrFailure() {
    if (error)
      return (
        <div className={classes.successFailureRoot}>
          <Typography variant="button" display="block" color="error">
            <ErrorIcon className={classes.successFailureIcon} />
            <span>Unable to submit Endpoint</span>
          </Typography>

          <Typography variant="overline" display="block" color="error">
            <span>{error}</span>
          </Typography>
        </div>
      );
    return (
      <div className={classes.successFailureRoot}>
        <Typography
          variant="button"
          display="block"
          style={{ color: "#008F07" }}
        >
          <CheckCircleIcon className={classes.successFailureIcon} />
          <span>
            Endpoint successfully{" "}
            {props.title === "Add Endpoint" ? "submitted" : "updated"}
          </span>
        </Typography>
      </div>
    );
  }

  // reset form state
  function resetForm() {
    setLoading(false);
    setFormData(null);
    setTypes([]);
    setSelectedTypeId("");
    setSchema(null);
    setSubmitted(false);
    setError(null);
    updateEndpointTypes();
  }

  // prepare form for new endpoint
  function submitNew() {
    props.setTitle("Add Endpoint");
    resetForm();
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby={props.title.replace(" ", "-")}
    >
      <DialogTitle id={props.title.replace(" ", "-")}>
        {props.title}
      </DialogTitle>
      <DialogContent className={classes.dialogRoot}>
        {submitted ? (
          showSuccessOrFailure()
        ) : (
          <>
            {loading ? (
              <CircularProgress className={classes.progress} />
            ) : (
              <>
                {props.title !== "Edit Endpoint" ? (
                  <FormControl className={classes.formControlRoot}>
                    <InputLabel htmlFor="endpoint-type">
                      Endpoint Type
                    </InputLabel>
                    <Select
                      value={selectedTypeId}
                      onChange={handleChangeType}
                      inputProps={{
                        name: "type",
                        id: "endpoint-type"
                      }}
                    >
                      {types.map((type, i) => {
                        return (
                          <MenuItem key={type.id} value={type.id}>
                            {type.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                ) : null}
                {schema && (
                  <Paper className={classes.formPaper}>
                    <Form
                      schema={schema.schema}
                      uiSchema={schema.uiSchema}
                      onSubmit={submitForm}
                      formData={formData}
                    >
                      <button
                        ref={btn => {
                          submitButton = btn;
                        }}
                        style={{ display: "none" }}
                      />
                    </Form>
                  </Paper>
                )}
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {submitted ? (
          <ButtonGroup variant="contained" color="primary">
            <Button onClick={submitNew}>Submit New</Button>
            <Button onClick={props.handleClose}>Close</Button>
          </ButtonGroup>
        ) : (
          !loading &&
          schema && (
            <>
              {props.currentEndpoint && (
                <Button
                  onClick={deleteEndpoint}
                  color="secondary"
                  variant="contained"
                >
                  Delete
                </Button>
              )}
              <Button
                onClick={() => submitButton.click()}
                color="primary"
                variant="contained"
              >
                {props.currentEndpoint ? "Update" : "Submit"}
              </Button>
            </>
          )
        )}
      </DialogActions>
    </Dialog>
  );
}

EndpointFormDialog.defaultProps = {
  title: "Form Dialog",
  open: false,
  handleClose: () => {}
};
