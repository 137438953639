import React, { Component } from "react";
import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { API } from "aws-amplify";
import "./style.scss";

export default class Methods extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      mode: "",
      methods: []
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      const api_resp = await this.getMethods();
      this.setState({ methods: api_resp["methods"] });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  strCapitalize(string_val) {
    return string_val.charAt(0).toUpperCase() + string_val.slice(1);
  }

  getMethods() {
    return API.get("tenant", "/method");
  }

  renderMethodsList(methods) {
    return [{}].concat(methods).map(
      (method, i) =>
        i !== 0
          ? <LinkContainer
            key={`${method.api_name + ' | ' + method.version + ' | ' + method.category + ' | ' + method.action}`}
            to={`/method/${method.api_name}/${method.version}/${method.category}/${method.action}`}
          >
            <ListGroupItem header={`${this.strCapitalize(method.api_name) + ' | ' + method.version + ' | ' + method.category + ' | ' + method.action}`}>
              {"Created: " + new Date(parseInt(method.created_at)).toLocaleString()}
            </ListGroupItem>
          </LinkContainer>
          : <LinkContainer
            key="new"
            to="/method/new"
          >
            <ListGroupItem>
              <h4>
                <b>{"\uFF0B"}</b> Create a new method
                </h4>
            </ListGroupItem>
          </LinkContainer>
    );
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Concerted</h1>
        <h2>Methods</h2>
        <p>You are currently unauthorized to view the list of methods.</p>
      </div>
    );
  }

  renderMethods() {
    return (
      <div className="Methods">
        <PageHeader>All Methods</PageHeader>
        <ListGroup>
          {!this.state.isLoading && this.renderMethodsList(this.state.methods)}
        </ListGroup>
      </div>
    );
  }

  render() {
    return (
      <div className="Methods">
        {this.props.isAuthenticated ? this.renderMethods() : this.renderLander()}
      </div>
    );
  }
}