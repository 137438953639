import React, { useEffect, useState } from "react";
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  TextField,
  DialogContent,
  DialogTitle,
  List,
  ListItemText,
  Divider,
  ButtonGroup,
  ListItem,
  Typography,
  Tooltip,
  FormControl
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
// aws
import { API } from "aws-amplify";
// jsonschema-form

const useStyles = makeStyles(() => ({
  progress: {
    display: "block",
    margin: "10px auto"
  },
  successFailureIcon: { verticalAlign: "middle", marginRight: "10px" },
  formControlRoot: { width: "100%" },
  dialogRoot: { minWidth: "100%" },
  listItemMargin: { marginBottom: "10px", display: "flex" },
  listText: { flex: "initial", marginRight: "10px" },
  copyIcon: { color: "#7f7f7f", cursor: "pointer" }
}));

const CopyButton = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [toolText, setToolText] = useState("Copy to clipboard");

  const setCopied = () => {
    setToolText("Copied!");
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
      setTimeout(() => {
        setToolText("Copy to clipboard");
      }, 200);
    }, 1000);
  };

  return (
    <CopyToClipboard text={props.text} onCopy={() => setCopied()}>
      <Tooltip open={open ? open : null} title={toolText} placement="top">
        <FileCopyIcon className={classes.copyIcon} />
      </Tooltip>
    </CopyToClipboard>
  );
};

export default function UnlockForm(props) {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [step2Info, setStep2Info] = useState({});
  const [endpointNameEntered, setEndpointNameEntered] = useState("");
  const [error, setError] = useState(false);
  const [errorName, setErrorName] = useState(false);

  const classes = useStyles();

  // get endpoint types on form open
  useEffect(() => {
    // reset form on open
    if (props.open) {
      resetForm();
    }
  }, [props.open, props.currentEndpoint]);

  async function unlockEndpoint() {
    setErrorName(false);
    setError(false);
    if (endpointNameEntered !== props.currentEndpoint.name) {
      setErrorName(true);
      return;
    }
    setLoading(true);

    try {
      let endpoint_res;
      if (props.currentEndpoint) {
        endpoint_res = await API.put(
          "config",
          `/endpoint/${props.currentEndpoint.id}/reset`
        );
      }

      if (endpoint_res.message) {
        throw endpoint_res.message;
      }
      setStep2Info({
        tenant_id: endpoint_res.tenant_id,
        id: endpoint_res.id,
        temporaryPassword: endpoint_res.temporaryPassword
      });
      setStep(2);
    } catch (e) {
      setError(e.message);
    } finally {
      props.populateTable();
      setLoading(false);
    }
  }

  function showSuccess() {
    return (
      <div className={classes.successFailureRoot}>
        <List>
          <Divider component="li" />
          <li>
            <Typography color="textSecondary" display="block" variant="caption">
              Tenant ID:
            </Typography>
          </li>
          <ListItem className={classes.listItemMargin}>
            <ListItemText
              primary={step2Info.tenant_id}
              className={classes.listText}
            />
            <CopyButton text={step2Info.tenant_id} />
          </ListItem>
          <Divider component="li" />
          <li>
            <Typography color="textSecondary" display="block" variant="caption">
              Endpoint ID:
            </Typography>
          </li>
          <ListItem className={classes.listItemMargin}>
            <ListItemText primary={step2Info.id} className={classes.listText} />
            <CopyButton text={step2Info.id} />
          </ListItem>
          <Divider component="li" />
          <li>
            <Typography color="textSecondary" display="block" variant="caption">
              One-Time Code:
            </Typography>
          </li>
          <ListItem className={classes.listItemMargin}>
            <ListItemText
              primary={step2Info.temporaryPassword}
              className={classes.listText}
            />
            <CopyButton text={step2Info.temporaryPassword} />
          </ListItem>
        </List>
      </div>
    );
  }

  // reset form state
  function resetForm() {
    setLoading(false);
    setStep(1);
    setStep2Info({});
    setEndpointNameEntered("");
    setErrorName(false);
    setError(false);
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="endpoint-unlock"
    >
      <DialogTitle id="endpoint-unlock">Endpoint Unlock</DialogTitle>
      <DialogContent className={classes.dialogRoot}>
        {step === 2 ? (
          showSuccess()
        ) : (
          <>
            {loading ? (
              <CircularProgress className={classes.progress} />
            ) : (
              <>
                <FormControl className={classes.formControlRoot}>
                  <Typography variant="subtitle1">
                    To reset the endpoint:{" "}
                    <span style={{ color: "red", fontWeight: "bold" }}>
                      {props.currentEndpoint && props.currentEndpoint.name}
                    </span>{" "}
                    , please type the name of the endpoint in the box below to
                    acknowledge that the Concerted Agent setup must be
                    performed.
                  </Typography>
                  <TextField
                    label="Endpoint Name"
                    error={errorName}
                    value={endpointNameEntered}
                    onChange={e => setEndpointNameEntered(e.target.value)}
                    fullWidth
                    margin="normal"
                  />

                  {errorName && (
                    <Typography variant="subtitle2" color="error">
                      Endpoint name does not match
                    </Typography>
                  )}
                  {error && (
                    <Typography variant="subtitle2" color="error">
                      {error}
                    </Typography>
                  )}
                </FormControl>
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {step === 2 ? (
          <ButtonGroup variant="contained" color="primary">
            <Button onClick={props.handleClose}>Close</Button>
          </ButtonGroup>
        ) : (
          !loading &&
          step === 1 && (
            <>
              <Button
                onClick={props.handleClose}
                color="secondary"
                variant="contained"
              >
                Cancel
              </Button>
              <Button
                onClick={unlockEndpoint}
                color="primary"
                variant="contained"
              >
                Confirm
              </Button>
            </>
          )
        )}
      </DialogActions>
    </Dialog>
  );
}

UnlockForm.defaultProps = {
  title: "Form Dialog",
  open: false,
  currentEndpoint: {},
  handleClose: () => {}
};
