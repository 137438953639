import React from "react";
import { Route, Switch } from "react-router-dom";

import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import Home from "./containers/Home";
import Login from "./containers/Login";
import TenantLogin from "./containers/TenantLogin";
import NotFound from "./containers/NotFound";
import Signup from "./containers/Signup";
import ResetPassword from "./containers/ResetPassword";
import Tenant from "./containers/Tenant";
import Method from "./containers/Method";
import Methods from "./containers/Methods";
import NewMethod from "./containers/NewMethod";
import Role from "./containers/Role";
import NewRole from "./containers/NewRole";
import Roles from "./containers/Roles";
import Users from "./containers/Users";
import NewUser from "./containers/NewUser";
import User from "./containers/User";
import Endpoints from "./containers/Endpoints";
import Pipelines from "./containers/Pipelines";
import PipelinesMapping from "./containers/PipelinesMapping";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/tenant_login" exact component={TenantLogin} props={childProps} />
    <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps} />
    <UnauthenticatedRoute path="/login/reset" exact component={ResetPassword} props={childProps} />
    <AuthenticatedRoute path="/method/new" exact component={NewMethod} props={childProps} />
    <AuthenticatedRoute path="/method/:api_name/:version/:category/:action" exact component={Method} props={childProps} />
    <AuthenticatedRoute path="/methods" exact component={Methods} props={childProps} />
    <AuthenticatedRoute path="/roles" exact component={Roles} props={childProps} />
    <AuthenticatedRoute path="/role/new" exact component={NewRole} props={childProps} />
    <AuthenticatedRoute path="/role/:role_name" exact component={Role} props={childProps} />
    <AuthenticatedRoute path="/users" exact component={Users} props={childProps} />
    <AuthenticatedRoute path="/user/new" exact component={NewUser} props={childProps} />
    <AuthenticatedRoute path="/user/:username" exact component={User} props={childProps} />
    <AuthenticatedRoute path="/tenant" exact component={Tenant} props={childProps} />
    <AuthenticatedRoute path="/endpoints" exact component={Endpoints} props={childProps} />
    <AuthenticatedRoute path="/pipelines" exact component={Pipelines} props={childProps} />
    <AuthenticatedRoute path="/pipelines/mapping/:id" exact component={PipelinesMapping} props={childProps} />
    { /* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>;