import React, { Component, Fragment } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem, NavDropdown, MenuItem } from "react-bootstrap";
import "./App.css";
import Routes from "./Routes";
import { Auth } from "aws-amplify";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
// import logo from '../public/assets/image/img/concerted-logo-720x140.png';

const theme = createMuiTheme({
  typography: {
    fontSize: 20
  },
  palette: {
    primary: {
      main: "#9900FF"
    }
  }
});

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true
    };
  }

  async componentDidMount() {
    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  };

  handleLogout = async event => {
    await Auth.signOut();

    this.userHasAuthenticated(false);

    this.props.history.push("/login");
  };

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
    };

    return (
      !this.state.isAuthenticating && (
        <ThemeProvider theme={theme}>
          <div className="App container">
            <Navbar fluid collapseOnSelect>
              <Navbar.Header>
                <Navbar.Brand>
                  <Link to="/">
                    Concerted
                    {/* <img className="img-responsive" src={logo} alt="logo"/>  */}
                  </Link>
                </Navbar.Brand>
                <Nav>
                  <LinkContainer to="/" exact>
                    <NavItem>Home</NavItem>
                  </LinkContainer>
                  {this.state.isAuthenticated && (
                    <>
                      <LinkContainer to="/endpoints">
                        <NavItem>Endpoints</NavItem>
                      </LinkContainer>
                      <LinkContainer to="/pipelines">
                        <NavItem>Pipelines</NavItem>
                      </LinkContainer>
                    </>
                  )}
                </Nav>
                <Navbar.Toggle />
              </Navbar.Header>
              <Navbar.Collapse>
                <Nav pullRight>
                  {this.state.isAuthenticated ? (
                    <Fragment>
                      <NavDropdown
                        eventKey={3}
                        title="Settings"
                        id="basic-nav-dropdown"
                      >
                        <LinkContainer to="/methods">
                          <MenuItem eventKey={3.1}>Methods</MenuItem>
                        </LinkContainer>
                        <LinkContainer to="/roles">
                          <MenuItem eventKey={3.1}>Roles</MenuItem>
                        </LinkContainer>
                        <LinkContainer to="/users">
                          <MenuItem eventKey={3.2}>Users</MenuItem>
                        </LinkContainer>
                      </NavDropdown>
                      <NavItem onClick={this.handleLogout}>Logout</NavItem>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <LinkContainer to="/signup">
                        <NavItem>Signup</NavItem>
                      </LinkContainer>
                      <LinkContainer to="/login">
                        <NavItem>Login</NavItem>
                      </LinkContainer>
                    </Fragment>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            <Routes childProps={childProps} />
          </div>
        </ThemeProvider>
      )
    );
  }
}

export default withRouter(App);
