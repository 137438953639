import React, { useEffect, useState } from "react";
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  ButtonGroup,
  Typography,
  MenuItem,
  LinearProgress,
  Grid,
  TextField
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ErrorIcon from "@material-ui/icons/Error";
// aws
import { API } from "aws-amplify";

const useStyles = makeStyles(theme => ({
  progress: {
    display: "block",
    margin: "10px auto"
  },
  successFailureRoot: {
    textAlign: "center"
  },
  successFailureIcon: { verticalAlign: "middle", marginRight: "10px" },
  formControlRoot: { width: "100%" },
  formPaper: { padding: "0px 25px 15px 25px" },
  dialogRoot: { minWidth: "500px" },
  gridContainer: {
    marginTop: "10px"
  },
  gridItem: {
    padding: "10px"
  },
  gridItemText: {
    textTransform: "uppercase",
    textAlign: "center",
    margin: "-10px",
    padding: "5px",
    color: "#FFFFFF",
    backgroundColor: theme.palette.primary.main
  },
  verticalAlign: {
    verticalAlign: "middle"
  }
}));

const formInitialState = {
  name: "",
  description: "",
  tags: "",
  srcEndpointId: "",
  destEndpointId: "",
  srcFlowFilterId: "",
  destFlowFilterId: "",
  srcDataDefinitionId: "",
  destDataDefinitionId: ""
};

const formInitialErrors = {
  name: false,
  description: false,
  tags: false,
  srcEndpoint: false,
  srcFlowFilterId: false,
  srcDataDefinitionId: false,
  destEndpointId: false,
  destFlowFilterId: false,
  destDataDefinitionId: false
};

export default function EndpointFormDialog(props) {
  const [loading, setLoading] = useState(false);
  const [currentPipeline, setCurrentPipeline] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [endpoints, setEndpoints] = useState([]);
  const [srcFlowFilters, setSrcFlowFilters] = useState([]);
  const [srcDataDefinitions, setSrcDataDefinitions] = useState([]);
  const [destFlowFilters, setDestFlowFilters] = useState([]);
  const [destDataDefinitions, setDestDataDefinitions] = useState([]);
  const [formData, setFormData] = useState(formInitialState);
  const [srcLoading, setSrcLoading] = useState(false);
  const [destLoading, setDestLoading] = useState(false);
  const [formErrors, setFormErrors] = useState(formInitialErrors);

  const classes = useStyles();

  // get endpoint types on form open
  useEffect(() => {
    // reset form and get available types
    if (props.open) {
      resetForm();
      getEndpoints();
    }
    // if editing an endpoint, get it's data and schema
    if (props.currentPipelineId) {
      setCurrentPipelineData();
    }
  }, [props.open, props.currentPipelineId]);

  useEffect(() => {
    if (endpoints.length && formData.srcEndpointId) {
      const endpointType = endpoints.filter(
        ep => ep.id === formData.srcEndpointId
      )[0].endpointType;
      getSrcFlowFilter(endpointType);
    }
  }, [endpoints, formData.srcEndpointId]);
  useEffect(() => {
    if (endpoints.length && formData.srcEndpointId) {
      const endpointType = endpoints.filter(
        ep => ep.id === formData.destEndpointId
      )[0].endpointType;
      getDestFlowFilter(endpointType);
    }
  }, [endpoints, formData.destEndpointId]);

  useEffect(() => {
    if (endpoints.length && formData.srcEndpointId && formData.srcEndpointId) {
      getSrcDataDefinition();
      // getSrcFlowFilter(endpointType);
    }
  }, [endpoints, formData.srcEndpointId, formData.srcFlowFilterId]);

  useEffect(() => {
    if (
      endpoints.length &&
      formData.destEndpointId &&
      formData.destEndpointId
    ) {
      getDestDataDefinition();
      // getdestFlowFilter(endpointType);
    }
  }, [endpoints, formData.destEndpointId, formData.destFlowFilterId]);

  async function deletePipeline() {
    setLoading(true);
    try {
      const delete_res = await API.del(
        "config",
        `/dataflow/${currentPipeline.id}`
      );
      if (delete_res.message) {
        throw delete_res;
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setSubmitted(true);
      setLoading(false);
      props.populateTable();
    }
  }

  async function setCurrentPipelineData() {
    setLoading(true);
    try {
      const pipeline = await API.get(
        "config",
        `/dataflow/${props.currentPipelineId}`
      );

      const tags = pipeline.tags ? pipeline.tags.join(", ") : "";
      setFormData({ ...pipeline, tags });
      setCurrentPipeline(pipeline);
    } catch (e) {
      alert(e);
    } finally {
      setLoading(false);
    }
  }

  async function getEndpoints() {
    setLoading(true);
    try {
      const types_res = await API.get("config", "/endpoint", {});
      setEndpoints(types_res.endpoints);
    } catch (e) {
      alert(e);
    } finally {
      setLoading(false);
    }
  }

  async function getFlowFilters(type) {
    try {
      const res = await API.get(
        "config",
        `/flowfilter?type=${type}&field=id&field=name&field=description&field=param1&field=param1Type`,
        {}
      );
      return res.flowfilters;
    } catch (e) {
      alert(e);
    }
  }

  async function getSrcFlowFilter(type) {
    setSrcLoading(true);
    const updatedFlowFilters = await getFlowFilters(type);
    setSrcFlowFilters(updatedFlowFilters);
    setSrcLoading(false);
  }

  async function getDestFlowFilter(type) {
    setDestLoading(true);
    const updatedFlowFilters = await getFlowFilters(type);
    setDestFlowFilters(updatedFlowFilters);
    setDestLoading(false);
  }

  async function getDataDefinition(type) {
    try {
      const flowFilterId =
        type === "src"
          ? formData.srcFlowFilterId
          : type && formData.destFlowFilterId;
      const res = await API.get(
        "config",
        `/datadefinition?flowFilterId=${flowFilterId}&field=name&field=id&field=description`,
        {}
      );
      return res.dataDefinitions;
    } catch (e) {
      alert(e);
    }
  }

  async function getSrcDataDefinition() {
    setSrcLoading(true);
    const srcDataDefinitions = await getDataDefinition("src");
    setSrcDataDefinitions(srcDataDefinitions);
    setSrcLoading(false);
  }

  async function getDestDataDefinition() {
    setDestLoading(true);
    const destDataDefinitions = await getDataDefinition("dest");
    setDestDataDefinitions(destDataDefinitions);
    setDestLoading(false);
  }

  async function submitForm(e) {
    e.preventDefault();
    if (checkErrors()) {
      return;
    }

    setLoading(true);

    try {
      const srcEndpointName = endpoints.filter(
        ep => ep.id === formData.srcEndpointId
      )[0].name;
      const destEndpointName = endpoints.filter(
        ep => ep.id === formData.destEndpointId
      )[0].name;

      const { tags, ...submitDataTemp } = formData;
      const tagsNew = tags.split(",");

      const submitData = {
        ...submitDataTemp,
        tags: tagsNew,
        srcEndpointName,
        destEndpointName,
        active: true
      };

      let res;
      if (!currentPipeline) {
        res = await API.post("config", "/dataflow", {
          body: { ...submitData, dataMapping: [] }
        });
      } else {
        res = await API.put("config", `/dataflow/${currentPipeline.id}`, {
          body: { ...submitData }
        });
      }

      if (res.message) {
        throw res.message;
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setSubmitted(true);
      setLoading(false);
      props.populateTable();
    }
  }

  function showSuccessOrFailure() {
    if (error)
      return (
        <div className={classes.successFailureRoot}>
          <Typography variant="button" display="block" color="error">
            <ErrorIcon className={classes.successFailureIcon} />
            <span>Unable to submit Endpoint</span>
          </Typography>

          <Typography variant="overline" display="block" color="error">
            <span>{error}</span>
          </Typography>
        </div>
      );
    return (
      <div className={classes.successFailureRoot}>
        <Typography
          variant="button"
          display="block"
          style={{ color: "#008F07" }}
        >
          <CheckCircleIcon className={classes.successFailureIcon} />
          <span>
            Pipeline successfully{" "}
            {props.title === "Add Pipeline" ? "created" : "updated"}
          </span>
        </Typography>
      </div>
    );
  }

  const handleChange = name => event => {
    setFormData({ ...formData, [name]: event.target.value });
  };

  function resetForm() {
    setCurrentPipeline(null);
    setFormData(formInitialState);
    setFormErrors(formInitialErrors);
    setLoading(false);
    setSubmitted(false);
    setError(null);
    setEndpoints([]);
    setSrcDataDefinitions([]);
    setSrcFlowFilters([]);
    setDestFlowFilters([]);
    setDestDataDefinitions([]);
    setSrcLoading(false);
    setDestLoading(false);
  }

  function checkErrors() {
    const errors = {};
    if (!formData.name || formData.name === "") errors.name = true;
    if (!formData.description || formData.description === "")
      errors.description = true;
    if (!formData.tags || formData.tags === "") errors.tags = true;
    if (!formData.srcEndpointId || formData.srcEndpointId === "")
      errors.srcEndpointId = true;
    if (!formData.srcFlowFilterId || formData.srcFlowFilterId === "")
      errors.srcFlowFilterId = true;
    if (!formData.srcDataDefinitionId || formData.srcDataDefinitionId === "")
      errors.srcDataDefinitionId = true;
    if (!formData.destEndpointId || formData.destEndpointId === "")
      errors.destEndpointId = true;
    if (!formData.destFlowFilterId || formData.destFlowFilterId === "")
      errors.destFlowFilterId = true;
    if (!formData.destDataDefinitionId || formData.destDataDefinitionId === "")
      errors.destDataDefinitionId = true;
    setFormErrors(errors);

    return Object.keys(errors).length > 0;
  }

  function displayErrors() {
    return Object.keys(formErrors).map((err, i) => {
      return formErrors[err] === true ? (
        <li key={i}>
          <Typography variant="subtitle2" color="error">
            {err.toString()} cannot be empty
          </Typography>
        </li>
      ) : null;
    });
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby={props.title.replace(" ", "-")}
    >
      <DialogTitle id={props.title.replace(" ", "-")}>
        {props.title}
      </DialogTitle>
      <DialogContent className={classes.dialogRoot}>
        {submitted ? (
          showSuccessOrFailure()
        ) : (
          <>
            {loading ? (
              <CircularProgress className={classes.progress} />
            ) : (
              <Paper className={classes.formPaper}>
                <ul style={{ paddingTop: "10px", marginBottom: "0px" }}>
                  {displayErrors()}
                </ul>
                <form noValidate autoComplete="off">
                  <TextField
                    error={formErrors.name}
                    label="Name"
                    value={formData.name}
                    onChange={handleChange("name")}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    error={formErrors.description}
                    label="Description"
                    value={formData.description}
                    onChange={handleChange("description")}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    error={formErrors.tags}
                    label="Tags"
                    value={formData.tags}
                    onChange={handleChange("tags")}
                    fullWidth
                    placeholder="tag1, tag2, ..."
                    margin="normal"
                  />
                  <Grid
                    container
                    justify="center"
                    spacing={1}
                    className={classes.gridContainer}
                  >
                    <Grid item xs={6}>
                      <Paper className={classes.gridItem}>
                        <Typography
                          variant="button"
                          display="block"
                          className={classes.gridItemText}
                        >
                          <span className={classes.verticalAlign}>Source</span>{" "}
                          <ArrowUpwardIcon className={classes.verticalAlign} />
                        </Typography>
                        <TextField
                          error={formErrors.srcEndpointId}
                          select
                          label="Endpoint"
                          value={formData.srcEndpointId}
                          onChange={handleChange("srcEndpointId")}
                          margin="normal"
                          fullWidth
                          disabled={!!props.currentPipelineId}
                        >
                          {endpoints.map(ep => (
                            <MenuItem key={ep.id} value={ep.id}>
                              {ep.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          error={formErrors.srcFlowFilterId}
                          select
                          label="Flow Filter"
                          value={formData.srcFlowFilterId}
                          onChange={handleChange("srcFlowFilterId")}
                          margin="normal"
                          fullWidth
                          disabled={
                            srcFlowFilters.length === 0 ||
                            !!props.currentPipelineId ||
                            srcLoading
                          }
                        >
                          {srcFlowFilters.map(sff => (
                            <MenuItem key={sff.id} value={sff.id}>
                              {sff.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          error={formErrors.srcDataDefinitionId}
                          select
                          label="Data Definition"
                          value={formData.srcDataDefinitionId}
                          onChange={handleChange("srcDataDefinitionId")}
                          margin="normal"
                          fullWidth
                          disabled={
                            srcDataDefinitions.length === 0 || srcLoading
                          }
                        >
                          {srcDataDefinitions.map(v => (
                            <MenuItem key={v.id} value={v.id}>
                              {v.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Paper>
                      {srcLoading && <LinearProgress color="secondary" />}
                    </Grid>
                    <Grid item xs={6}>
                      <Paper className={classes.gridItem}>
                        <Typography
                          variant="button"
                          display="block"
                          className={classes.gridItemText}
                        >
                          <span className={classes.verticalAlign}>
                            Destination
                          </span>{" "}
                          <ArrowDownwardIcon
                            className={classes.verticalAlign}
                          />
                        </Typography>
                        <TextField
                          error={formErrors.destEndpointId}
                          select
                          label="Endpoint"
                          value={formData.destEndpointId}
                          onChange={handleChange("destEndpointId")}
                          margin="normal"
                          fullWidth
                        >
                          {endpoints.map(ep => (
                            <MenuItem key={ep.id} value={ep.id}>
                              {ep.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          error={formErrors.destFlowFilterId}
                          select
                          label="Flow Filter"
                          value={formData.destFlowFilterId}
                          onChange={handleChange("destFlowFilterId")}
                          margin="normal"
                          fullWidth
                          disabled={destFlowFilters.length === 0 || destLoading}
                        >
                          {destFlowFilters.map(dff => (
                            <MenuItem key={dff.id} value={dff.id}>
                              {dff.name}
                            </MenuItem>
                          ))}
                        </TextField>

                        <TextField
                          error={formErrors.destDataDefinitionId}
                          select
                          label="Data Definition"
                          value={formData.destDataDefinitionId}
                          onChange={handleChange("destDataDefinitionId")}
                          margin="normal"
                          fullWidth
                          disabled={
                            destDataDefinitions.length === 0 || destLoading
                          }
                        >
                          {destDataDefinitions.map(v => (
                            <MenuItem key={v.id} value={v.id}>
                              {v.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Paper>
                      {destLoading && <LinearProgress color="secondary" />}
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {submitted ? (
          <ButtonGroup variant="contained" color="primary">
            <Button onClick={props.handleClose}>Close</Button>
          </ButtonGroup>
        ) : (
          !loading && (
            <>
              {currentPipeline && (
                <Button
                  onClick={deletePipeline}
                  color="secondary"
                  variant="contained"
                >
                  Delete
                </Button>
              )}
              <Button onClick={submitForm} color="primary" variant="contained">
                {currentPipeline ? "Update" : "Create"}
              </Button>
            </>
          )
        )}
      </DialogActions>
    </Dialog>
  );
}

EndpointFormDialog.defaultProps = {
  title: "Form Dialog",
  open: false,
  handleClose: () => {}
};
