import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Box,
  Typography,
  Link,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  CircularProgress
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import SettingsIcon from "@material-ui/icons/Settings";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import InfoIcon from "@material-ui/icons/InfoOutlined";

import EndpointFormDialog from "../../components/EndpointFormDialog";
import UnlockForm from "../../components/UnlockForm";
import { API } from "aws-amplify";
import "./style.scss";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#3f3f3f",
    color: theme.palette.common.white,
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  }
}))(TableRow);

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: "1rem",
    marginTop: theme.spacing(1)
  },
  margin: {
    margin: theme.spacing(1)
  },
  align: {
    verticalAlign: "middle"
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  edit: {
    cursor: "pointer",
    color: "#3F3F3F",
    "& svg": {
      width: "1.5em",
      height: "1.5em"
    }
  },
  loader: {
    textAlign: "center",
    width: "100%",
    pointerEvents: "none"
  }
}));

export default function Endpoints() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [openUnlock, setOpenUnlock] = useState(false);
  const [title, setTitle] = useState("Add Endpoint");
  const [currentEndpoint, setCurrentEndpoint] = useState(null);
  const [endpoints, setEndpoints] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    populateTable();
  }, []);

  async function populateTable() {
    setLoading(true);
    try {
      const table_res = await API.get("config", "/endpoint");
      setEndpoints(table_res.endpoints);
    } catch (e) {
      alert(e);
    }
    setLoading(false);
  }

  function getStatusColor(status) {
    switch (status) {
      case "Healthy":
        return "#009E0F";
      case "Inactive":
      case "Failed":
        return "#F20800";
      case "Connected":
        return "#E0D900";
      case "Warning":
      case "Pending":
        return "#FF9900";
      default:
        return "darkgrey";
    }
  }

  function handleClickUnlock(ep) {
    setCurrentEndpoint(ep);
    setOpenUnlock(true);
  }

  function handleClickOpen(title, ep) {
    setCurrentEndpoint(ep);
    setTitle(title);
    setOpen(true);
  }

  function handleClose() {
    setCurrentEndpoint(null);
    setOpen(false);
  }

  function handleCloseUnlock() {
    setCurrentEndpoint(null);
    setOpenUnlock(false);
  }

  return (
    <>
      <Box display="flex">
        <Button
          disabled={loading}
          className={classes.margin}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleClickOpen("Add Endpoint", null)}
        >
          <AddIcon />
          New
        </Button>
        <Typography variant="subtitle1" display="block">
          <Link
            color="inherit"
            style={{ cursor: "pointer", pointerEvents: loading && "none" }}
          >
            <Box m={1}>
              Get help setting up an endpoint{" "}
              <InfoIcon variant="outlined" className={classes.align} />
            </Box>
          </Link>
        </Typography>
      </Box>

      <EndpointFormDialog
        open={open}
        handleClose={handleClose}
        title={title}
        populateTable={populateTable}
        currentEndpoint={currentEndpoint}
        setTitle={setTitle}
      />

      <UnlockForm
        open={openUnlock}
        handleClose={handleCloseUnlock}
        populateTable={populateTable}
        currentEndpoint={currentEndpoint}
      />

      <Paper className={classes.root}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Edit</StyledTableCell>
              <StyledTableCell align="center">Unlock</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>Connector Type</StyledTableCell>
              <StyledTableCell>Tag</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <StyledTableRow>
                <TableCell colSpan={6} className={classes.loader}>
                  <Typography
                    variant="button"
                    display="block"
                    color="primary"
                    gutterBottom
                  >
                    <CircularProgress />
                    <br />
                    Loading endpoints...
                  </Typography>
                </TableCell>
              </StyledTableRow>
            ) : (
                endpoints.map(ep => (
                  <StyledTableRow key={ep.name}>
                    <TableCell
                      onClick={() => handleClickOpen("Edit Endpoint", ep)}
                      scope="row"
                      className={classes.edit}
                      align="center"
                    >
                      <SettingsIcon />
                    </TableCell>
                    <TableCell
                      onClick={() => handleClickUnlock(ep)}
                      scope="row"
                      className={classes.edit}
                      align="center"
                    >
                      {ep.externalIdentity === true && <LockOpenIcon />}
                    </TableCell>
                    <TableCell>{ep.name}</TableCell>
                    <TableCell align="center">
                      <Chip
                        style={{
                          color: "#fff",
                          backgroundColor: getStatusColor(ep.status)
                        }}
                        label={ep.status}
                      />
                    </TableCell>
                    <TableCell>{ep.description}</TableCell>
                    <TableCell>{ep.endpointType}</TableCell>
                    <TableCell>tags</TableCell>
                  </StyledTableRow>
                ))
              )}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
}
