import React, { Component } from "react";
import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { API } from "aws-amplify";
import "./style.scss";

export default class Roles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      mode: "",
      roles: []
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      const api_resp = await this.getRoles();
      this.setState({ roles: api_resp["roles"] });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  getRoles() {
    return API.get("tenant", "/role");
  }

  renderRolesList(roles) {
    return [{}].concat(roles).map(
      (role, i) =>
        i !== 0
          ? <LinkContainer
            key={`${role.role_name}`}
            to={`/role/${role.role_name}`}
          >
            <ListGroupItem header={`${role.role_name}`}>
              {"Created: " + new Date(parseInt(role.created_at)).toLocaleString()}
            </ListGroupItem>
          </LinkContainer>
          : <LinkContainer
            key="new"
            to="/role/new"
          >
            <ListGroupItem>
              <h4>
                <b>{"\uFF0B"}</b> Create a new role
                </h4>
            </ListGroupItem>
          </LinkContainer>
    );
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Concerted</h1>
        <h2>Roles</h2>
        <p>You are currently unauthorized to view the list of roles.</p>
      </div>
    );
  }

  renderRoles() {
    return (
      <div className="Roles">
        <PageHeader>All Roles</PageHeader>
        <ListGroup>
          {!this.state.isLoading && this.renderRolesList(this.state.roles)}
        </ListGroup>
      </div>
    );
  }

  render() {
    return (
      <div className="Roles">
        {this.props.isAuthenticated ? this.renderRoles() : this.renderLander()}
      </div>
    );
  }
}