import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  MenuItem,
  Grid,
  Icon,
  IconButton,
  CircularProgress
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";

import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import SettingsIcon from "@material-ui/icons/Settings";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import classNames from "classnames";

import { API } from "aws-amplify";
import "./style.scss";
import CustomizedTreeView from "../../components/CustomizedTreeView";

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  }
}))(TableRow);

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: "1rem",
    marginTop: theme.spacing(1),
    height: "25vh",
    overflowY: "scroll",
    "@media all and (max-width: 991px)": {
      overflowX: "scroll"
    }
  },
  margin: {
    margin: theme.spacing(1)
  },
  align: {
    verticalAlign: "middle"
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  edit: {
    cursor: "pointer",
    color: "#3F3F3F",
    "& svg": {
      width: "1.3em",
      height: "1.3em"
    }
  },
  deleteIcon: {
    color: "#F20800"
  },
  textField: {
    minWidth: "200px"
  },
  paperTree: {
    // padding: "10px"
    height: "100%"
  },
  treeHeading: {
    // backgroundColor: "grey",
    padding: "8px",
    backgroundColor: "#fafafa",
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.05), 0px 1px 1px 0px rgba(0,0,0,0.01), 0px 2px 1px -1px rgba(0,0,0,0.01)"
  },
  treeText: {
    pointerEvents: "none",
    fontSize: "1.4rem !important",
    letterSpacing: "1px !important",
    textAlign: "center"
  },
  treeBody: {
    padding: "15px",
    height: "44vh",
    overflow: "scroll"
  },
  menu: {
    width: "100%"
  },
  loader: {
    margin: "0 auto",
    textAlign: "center",
    padding: "20px",
    width: "100%",
    pointerEvents: "none"
  },
  infoMsg: {
    padding: "10px",
    backgroundColor: "#ddd"
  }
}));

export default function PipelinesMapping(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [dataFlow, setDataFlow] = useState(null);
  const [srcDataDefinition, setSrcDataDefinition] = useState(null);
  const [destDataDefinition, setDestDataDefinition] = useState(null);
  const [dataMapping, setDataMapping] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [snack, setSnack] = useState({ open: false, message: "" });

  const dataFlowId = props.match.params.id;

  // get src and dest data aliases
  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    setLoading(true);
    const df = await getDataFlow();
    setDataFlow(df);
    setDataMapping(df.dataMapping);
    await getSrcDataDefinition(df);
    await getDestDataDefinition(df);
    setLoading(false);
  }

  async function getSrcDataDefinition(df) {
    const srcDataDefinition = await getDataDefinition(df.srcDataDefinitionId);
    setSrcDataDefinition(srcDataDefinition);
  }

  async function getDestDataDefinition(df) {
    const destDataDefinition = await getDataDefinition(df.destDataDefinitionId);
    setDestDataDefinition(destDataDefinition);
  }

  async function getDataFlow() {
    try {
      const dataFlow = await API.get("config", `/dataflow/${dataFlowId}`);
      return dataFlow;
    } catch (e) {
      alert(e);
    }
  }

  async function getDataDefinition(dataDefinitionId) {
    try {
      const dataDefinition = await API.get(
        "config",
        `/datadefinition/${dataDefinitionId}?field=name&field=id&field=treeView&field=dataPointsAliases&field=description`
      );
      return dataDefinition;
    } catch (e) {
      alert(e);
    }
  }

  function addNewRow() {
    setSubmitted(false);
    let dataMappingNew = dataMapping ? [...dataMapping] : [];
    dataMappingNew.push({
      value: "",
      nullIfEmpty: false,
      dest: ""
    });
    setDataMapping(dataMappingNew);
  }

  function deleteRow(index) {
    let dataMappingNew = [...dataMapping];
    dataMappingNew = dataMappingNew.filter((v, i) => i !== index);
    setDataMapping(dataMappingNew);
  }

  function handleChangeDropdown(e, i, type) {
    let dataMappingNew = [...dataMapping];
    dataMappingNew[i][type] = e.target.value;
    setDataMapping(dataMappingNew);
  }

  function checkError() {
    const errors = dataMapping.find(
      dm => dm.value.length === 0 || dm.dest.length === 0
    );
    if (errors && Object.keys(errors).length !== 0)
      return "Cannot have empty data mappings";
    return null;
  }

  async function submitDataflow() {
    setLoading(true);
    try {
      const err = checkError();
      if (err) throw err;

      const body = {
        dataMapping,
        active: dataFlow.active,
        tags: dataFlow.tags,
        description: dataFlow.description,
        destDataDefinitionId: dataFlow.destDataDefinitionId,
        destEndpointId: dataFlow.destEndpointId,
        destFlowFilterId: dataFlow.destFlowFilterId,
        id: dataFlow.id,
        name: dataFlow.name,
        srcDataDefinitionId: dataFlow.srcDataDefinitionId,
        srcEndpointId: dataFlow.srcEndpointId,
        srcFlowFilterId: dataFlow.srcFlowFilterId,
        srcEndpointName: dataFlow.srcEndpointName,
        destEndpointName: dataFlow.destEndpointName
      };
      const res = await API.put("config", `/dataflow/${dataFlow.id}`, { body });
      if (!res || res.message) {
        throw res.message;
      } else {
        setSubmitted(true);
        setSnack({ open: true, message: "Updated dataflows successfully" });
      }
    } catch (e) {
      setSnack({ open: true, message: e });
      alert(e);
    }
    setLoading(false);
  }

  function handleCloseSnackbar() {
    setSnack({ open: false, message: "" });
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={snack.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <SnackbarContent
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.message}>
              <Icon />
              {snack.message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          ]}
        />
      </Snackbar>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Button
            disabled={loading}
            className={classes.margin}
            variant="contained"
            color="default"
            size="small"
            onClick={props.history.goBack}
          >
            <ArrowBackIcon />
          </Button>
          <Button
            disabled={loading}
            className={classes.margin}
            variant="contained"
            color="primary"
            size="small"
            onClick={addNewRow}
          >
            <AddIcon />
            New Row
          </Button>
        </Box>

        <Button
          className={classes.margin}
          variant="contained"
          color="primary"
          size="small"
          onClick={submitDataflow}
          disabled={loading || !dataMapping || dataMapping.length === 0}
        >
          <AddIcon />
          Save
        </Button>
      </Box>
      {!loading && (
        <Paper className={classes.root}>
          <Table size="small" style={{ height: "100%" }}>
            <TableBody>
              {destDataDefinition &&
              destDataDefinition.dataPointsAliases &&
              srcDataDefinition &&
              srcDataDefinition.dataPointsAliases ? (
                dataMapping && dataMapping.length > 0 ? (
                  dataMapping.map((dm, i) => {
                    return (
                      <StyledTableRow key={i}>
                        <TableCell
                          align="center"
                          scope="row"
                          className={classNames(
                            classes.edit,
                            classes.deleteIcon
                          )}
                          onClick={() => deleteRow(i)}
                        >
                          <DeleteForeverIcon />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            select
                            className={classes.textField}
                            value={dm.value}
                            onChange={e => handleChangeDropdown(e, i, "value")}
                            SelectProps={{
                              MenuProps: {
                                className: classes.menu
                              }
                            }}
                            fullWidth
                            margin="dense"
                          >
                            {srcDataDefinition &&
                              Object.entries(
                                srcDataDefinition.dataPointsAliases
                              ).map(dp => (
                                <MenuItem key={dp[1]} value={dp[1]}>
                                  {dp[0]}
                                </MenuItem>
                              ))}
                          </TextField>
                        </TableCell>
                        <TableCell
                          // onClick={() => handleClickOpen("Edit Endpoint", df)}
                          scope="row"
                          align="center"
                          className={classes.edit}
                        >
                          <SettingsIcon />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            select
                            className={classes.textField}
                            value={dm.dest}
                            onChange={e => handleChangeDropdown(e, i, "dest")}
                            SelectProps={{
                              MenuProps: {
                                className: classes.menu
                              }
                            }}
                            fullWidth
                            margin="dense"
                          >
                            {destDataDefinition &&
                              Object.entries(
                                destDataDefinition.dataPointsAliases
                              ).map(dp => (
                                <MenuItem key={dp[1]} value={dp[1]}>
                                  {dp[0]}
                                </MenuItem>
                              ))}
                          </TextField>
                        </TableCell>
                        <TableCell />
                      </StyledTableRow>
                    );
                  })
                ) : (
                  <StyledTableRow>
                    <TableCell
                      colSpan={9}
                      className={classes.loader}
                      style={{ padding: "20px" }}
                    >
                      <Typography
                        variant="button"
                        display="block"
                        color="primary"
                        gutterBottom
                      >
                        {submitted
                          ? "Data mappings updated"
                          : "No mappings yet."}
                      </Typography>
                    </TableCell>
                  </StyledTableRow>
                )
              ) : (
                <StyledTableRow>
                  <TableCell
                    colSpan={9}
                    className={classes.loader}
                    style={{ padding: "20px" }}
                  >
                    <Typography
                      variant="button"
                      display="block"
                      color="secondary"
                      gutterBottom
                    >
                      No data points found
                    </Typography>
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      )}
      <Grid container spacing={5} style={{ marginTop: "20px" }}>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paperTree}>
            <div className={classes.treeHeading}>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.treeText}
              >
                Source Schema Tree View
              </Typography>
            </div>
            {loading ? (
              <Typography
                className={classes.loader}
                variant="button"
                display="block"
                color="primary"
                gutterBottom
              >
                <CircularProgress />
                <br />
                Loading
              </Typography>
            ) : (
              <div className={classes.treeBody}>
                <CustomizedTreeView
                  treeView={srcDataDefinition && srcDataDefinition.treeView}
                />
              </div>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paperTree}>
            <div className={classes.treeHeading}>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.treeText}
              >
                Destination Schema Tree View
              </Typography>
            </div>
            {loading ? (
              <Typography
                className={classes.loader}
                variant="button"
                display="block"
                color="primary"
                gutterBottom
              >
                <CircularProgress />
                <br />
                Loading
              </Typography>
            ) : (
              <div className={classes.treeBody}>
                <CustomizedTreeView
                  treeView={destDataDefinition && destDataDefinition.treeView}
                />
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
