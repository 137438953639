import React, { Component } from "react";
import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { API } from "aws-amplify";
import "./style.scss";

export default class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      mode: "",
      users: []
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      const api_resp = await this.getUsers();
      this.setState({ users: api_resp["users"] });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  getUsers() {
    return API.get("tenant", "/user");
  }

  renderUsersList(users) {
    return [{}].concat(users).map(
      (user, i) =>
        i !== 0
          ? <LinkContainer
            key={`${user.username}`}
            to={`/user/${user.username}`}
          >
            <ListGroupItem header={`${user.username}`}>
              <span className="userLeft">{"Status: " + user.user_status}</span>
              <span className="userRight">{"Created: " + new Date(parseInt(user.created_at)).toLocaleString()}</span>
            </ListGroupItem>
          </LinkContainer>
          : <LinkContainer
            key="new"
            to="/user/new"
          >
            <ListGroupItem>
              <h4>
                <b>{"\uFF0B"}</b> Create a new user
                </h4>
            </ListGroupItem>
          </LinkContainer>
    );
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Concerted</h1>
        <h2>Users</h2>
        <p>You are currently unauthorized to view the list of users.</p>
      </div>
    );
  }

  renderUsers() {
    return (
      <div className="Users">
        <PageHeader>All Users</PageHeader>
        <ListGroup>
          {!this.state.isLoading && this.renderUsersList(this.state.users)}
        </ListGroup>
      </div>
    );
  }

  render() {
    return (
      <div className="Users">
        {this.props.isAuthenticated ? this.renderUsers() : this.renderLander()}
      </div>
    );
  }
}