// import { config } from "aws-sdk/global";
const local = {
  s3: {
    REGION: "NOT_USED",
    BUCKET: "NOT_USED"
  },
  apiGatewayTenant: {
    REGION: "us-east-1",
    URL: "https://tenant-sb.concerted.io/v1"
  },
  apiGatewayConfig: {
    REGION: "us-east-1",
    URL: "https://config-sb.concerted.io/v1"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_kaUWtd0Np",
    APP_CLIENT_ID: "3dkng3ijvrgiq4kvp36kc2j6hj"
    //   IDENTITY_POOL_ID: "us-east-1:bded4d96-6f8f-41ec-a753-6c459b8bca36"
  }
};

const dynamic = {
  s3: {
    REGION: "NOT_USED",
    BUCKET: "NOT_USED"
  },
  apiGatewayTenant: {
    REGION: "us-east-1",
    URL: process.env.REACT_APP_TENANT_REST_API_URL
  },
  apiGatewayConfig: {
    REGION: "us-east-1",
    URL: process.env.REACT_APP_CONFIG_REST_API_URL
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: process.env.REACT_APP_COGNITO_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID
    //   IDENTITY_POOL_ID: "us-east-1:bded4d96-6f8f-41ec-a753-6c459b8bca36"
  }
};

function config() {
  switch (process.env.REACT_APP_STAGE) {
    case "local":
      return local;
    case undefined:
      return local;
    case null:
      return local;
    default:
      return dynamic;
  }
}

export default {
  ...config()
};
