import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  CircularProgress
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import SettingsIcon from "@material-ui/icons/Settings";
import DirectionsIcon from "@material-ui/icons/Directions";

import PipelineFormDialog from "../../components/PipelineFormDialog";
import { API } from "aws-amplify";
import "./style.scss";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#3f3f3f",
    color: theme.palette.common.white,
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  }
}))(TableRow);

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: "1rem",
    marginTop: theme.spacing(1),
    "@media all and (max-width: 991px)": {
      overflowX: "scroll"
    }
  },
  margin: {
    margin: theme.spacing(1)
  },
  align: {
    verticalAlign: "middle"
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  edit: {
    cursor: "pointer",
    color: "#3F3F3F",
    "& svg": {
      width: "1.5em",
      height: "1.5em"
    }
  },
  loader: {
    textAlign: "center",
    width: "100%",
    pointerEvents: "none"
  }
}));

export default function Pipelines({ history }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("Add Endpoint");
  const [currentPipelineId, setCurrentPipelineId] = useState(null);
  const [dataFlows, setDataFlows] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    populateTable();
  }, []);

  async function populateTable() {
    setLoading(true);
    try {
      const table_res = await API.get("config", "/dataflow");
      setDataFlows(table_res.dataFlows);
    } catch (e) {
      alert(e);
    }
    setLoading(false);
  }

  function handleClickOpen(title, pl) {
    if (pl) {
      setCurrentPipelineId(pl.id);
    } else {
      setCurrentPipelineId(null);
    }
    setTitle(title);
    setOpen(true);
  }

  function handleClose() {
    setCurrentPipelineId(null);
    setOpen(false);
  }

  function getStatusColor(status) {
    switch (status) {
      case "Healthy":
        return "#009E0F";
      case "Failed":
        return "#F20800";
      case "Warning":
      case "Pending":
        return "#FF9900";
      default:
        return "darkgrey";
    }
  }

  function handleClickOpenMap(df) {
    history.push(`/pipelines/mapping/${df.id}`, { df });
  }

  return (
    <>
      <Box display="flex">
        <Button
          disabled={loading}
          className={classes.margin}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleClickOpen("Add Pipeline")}
        >
          <AddIcon />
          New
        </Button>
      </Box>

      <PipelineFormDialog
        open={open}
        handleClose={handleClose}
        title={title}
        populateTable={populateTable}
        currentPipelineId={currentPipelineId}
        setTitle={setTitle}
      />

      <Paper className={classes.root}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Edit</StyledTableCell>
              <StyledTableCell>Map</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Source</StyledTableCell>
              <StyledTableCell>Destination</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Velocity</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>Tag</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <StyledTableRow>
                <TableCell colSpan={9} className={classes.loader}>
                  <Typography
                    variant="button"
                    display="block"
                    color="primary"
                    gutterBottom
                  >
                    <CircularProgress />
                    <br />
                    Loading pipelines...
                  </Typography>
                </TableCell>
              </StyledTableRow>
            ) : (
              dataFlows.map(df => (
                <StyledTableRow key={df.name}>
                  <TableCell
                    onClick={() => handleClickOpen("Edit Pipeline", df)}
                    scope="row"
                    className={classes.edit}
                  >
                    <SettingsIcon />
                  </TableCell>
                  <TableCell
                    onClick={() => handleClickOpenMap(df)}
                    scope="row"
                    className={classes.edit}
                  >
                    <DirectionsIcon />
                  </TableCell>
                  <TableCell>{df.name}</TableCell>
                  <TableCell>{df.srcEndpointName}</TableCell>
                  <TableCell>{df.destEndpointName}</TableCell>
                  <TableCell align="center">
                    <Chip
                      style={{
                        color: "#fff",
                        backgroundColor: getStatusColor(df.status)
                      }}
                      label={df.status}
                    />
                  </TableCell>
                  <TableCell>{df.velocity}</TableCell>
                  <TableCell>{df.description}</TableCell>
                  <TableCell>
                    {df.tags &&
                      df.tags.map((tag, i) => (
                        <Chip
                          key={i}
                          variant="outlined"
                          size="small"
                          label={tag}
                        />
                      ))}
                  </TableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
}
