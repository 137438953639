import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { API } from "aws-amplify";
import PhoneInput from 'react-phone-number-input'
import Select from 'react-select';
import "./style.scss";

export default class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: null,
      isDeleting: null,
      username: "",
      given_name: "",
      family_name: "",
      email: "",
      address: {
          address1: "",
          address2: "",
          city: "",
          state: "",
          zipcode: ""
      },
      phone_number: "",
      roles: [],
      enabled: null,
      rolesOptions: []
    };
  }

  async componentDidMount() {
    try {
      const res = await this.getUser();
      const { username, given_name, family_name, email, roles, address, phone_number, enabled } = res;

      this.setState({
        username,
        given_name,
        family_name,
        email,
        address,
        phone_number,
        roles,
        enabled
      });

      const roles_res = await this.getRoles();
      let role_names = roles_res["roles"].map(a => (
        {
          value: a.role_name,
          label: a.role_name
        }
      ));
      this.setState({
        rolesOptions: role_names
      });
    } catch (e) {
      alert(e);
    }
  }

  getUser() {
    return API.get("tenant", `/user/${this.props.match.params.username}`);
  }

  getRoles() {
    return API.get("tenant", `/role`);
  }
  
  validateForm() {
    return this.state.username.length > 0 &&
    this.state.given_name.length > 0 &&
    this.state.family_name.length > 0 &&
    this.state.email.length > 0 &&
    this.state.phone_number.length > 0 &&
    this.state.roles.length > 0;
  }
  
  handleRolesChange = (selectedOption) => {
    let tmp_roles = selectedOption.map(a => a.value);
    this.setState({
      roles: tmp_roles
    });
  }
  
  handleAddressChange = event => {
    var tmp_address = this.state.address;
    tmp_address[event.target.id] = event.target.value;
    this.setState({
      address: tmp_address
    });
  }
  
  handleChange = event => {
    const id = event.target.type === 'checkbox' ? event.target.name : event.target.id;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      [id]: value
    });
  }
  
  handleSubmit = async event => {
    event.preventDefault();
  
    this.setState({ isLoading: true });
  
    try {
      await this.saveUser({
        username: this.state.username,
        given_name: this.state.given_name,
        family_name: this.state.family_name,
        email: this.state.email,
        address: this.state.address,
        phone_number: this.state.phone_number,
        roles: this.state.roles
      });
      this.props.history.push("/users");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  saveUser(user) {
    return API.put("tenant", `/user/${this.props.match.params.username}`, {
      body: user
    });
  }

  handleDelete = async event => {
    event.preventDefault();
  
    const confirmed = window.confirm(
      "Are you sure you want to delete this user?"
    );
  
    if (!confirmed) {
      return;
    }
  
    this.setState({ isDeleting: true });

    try {
      await this.deleteUser();
      this.props.history.push("/users");
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  }
  
  deleteUser() {
    return API.del("tenant", `/user/${this.props.match.params.username}`);
  }

  render() {
    return (
      <div className="User">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="username">
            <ControlLabel>Username</ControlLabel>
            <FormControl
              autoFocus
              onChange={this.handleChange}
              value={this.state.username}
              type="text"
            />
          </FormGroup>
          <FormGroup controlId="given_name">
            <ControlLabel>First Name</ControlLabel>
            <FormControl
              autoFocus
              onChange={this.handleChange}
              value={this.state.given_name}
              type="text"
            />
          </FormGroup>
          <FormGroup controlId="family_name">
            <ControlLabel>Last Name</ControlLabel>
            <FormControl
              autoFocus
              onChange={this.handleChange}
              value={this.state.family_name}
              type="text"
            />
          </FormGroup>
          <FormGroup controlId="email">
            <ControlLabel>Email Address</ControlLabel>
            <FormControl
              autoFocus
              onChange={this.handleChange}
              value={this.state.email}
              type="text"
            />
          </FormGroup>
          <ControlLabel>Address</ControlLabel>
          <div className="formAddress">
            <FormGroup controlId="address1">
              <ControlLabel>Address Line 1</ControlLabel>
              <FormControl
                autoFocus
                onChange={this.handleAddressChange}
                value={this.state.address.address1}
                type="text"
              />
            </FormGroup>
            <FormGroup controlId="address2">
              <ControlLabel>Address Line 2</ControlLabel>
              <FormControl
                autoFocus
                onChange={this.handleAddressChange}
                value={this.state.address.address2}
                type="text"
              />
            </FormGroup>
            <div className="addressCityStZip">
              <FormGroup className="addressCity" controlId="city">
                <ControlLabel>City</ControlLabel>
                <FormControl
                  autoFocus
                  onChange={this.handleAddressChange}
                  value={this.state.address.city}
                  type="text"
                />
              </FormGroup>
              <FormGroup className="addressState" controlId="state">
                <ControlLabel>State</ControlLabel>
                <FormControl
                  autoFocus
                  onChange={this.handleAddressChange}
                  value={this.state.address.state}
                  type="text"
                />
              </FormGroup>
              <FormGroup className="addressZip" controlId="zipcode">
                <ControlLabel>Zipcode</ControlLabel>
                <FormControl
                  autoFocus
                  onChange={this.handleAddressChange}
                  value={this.state.address.zipcode}
                  type="text"
                />
              </FormGroup>
            </div>
          </div>
          <FormGroup controlId="phone_number" bsSize="large">
            <ControlLabel>Phone Number</ControlLabel>
            <PhoneInput
              country="US"
              placeholder="Enter phone number"
              onChange={ phone_number => this.setState({ phone_number }) }
              value={this.state.phone_number}
            />
          </FormGroup>
          <FormGroup controlId="roles">
            <ControlLabel>Roles</ControlLabel>
            <Select
              isMulti
              isClearable
              isSearchable
              placeholder={`Select all roles that apply...`}
              value={this.state.roles.map(a => ({value: a, label: a}))}
              onChange={this.handleRolesChange}
              options={this.state.rolesOptions}
            />
          </FormGroup>
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Save"
            loadingText="Saving…"
          />
          <LoaderButton
            block
            bsStyle="danger"
            bsSize="large"
            isLoading={this.state.isDeleting}
            onClick={this.handleDelete}
            text="Delete"
            loadingText="Deleting…"
          />
        </form>
      </div>
    );
  }
}