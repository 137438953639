import React, { Component } from "react";
import { FormGroup, FormControl, Table, ControlLabel, Checkbox, Radio } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { API } from "aws-amplify";
import "./style.scss";

export default class Role extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: null,
      isDeleting: null,
      role_name: "",
      description: "",
      default_role: false,
      methods_allow: {},
      methods: []
    };
  }

  async componentDidMount() {
    try {
      const res = await this.getRole();
      const { role_name, description, default_role, methods_allow } = res;

      this.setState({
        role_name,
        description,
        default_role,
        methods_allow
      });

      const methods_res = await this.getMethods();
      
      this.setState({
        methods: methods_res.methods
      })
    } catch (e) {
      alert(e);
    }
  }

  getRole() {
    return API.get("tenant", `/role/${this.props.match.params.role_name}`);
  }

  getMethods() {
    return API.get("tenant", "/method");
  }
  
  validateForm() {
    return this.state.description.length > 0;
  }

  methodState(index) {
    const tmpMethod = this.state.methods[index]
    const methodName = tmpMethod.api_name + '|' + tmpMethod.version + '|' + tmpMethod.category + '|' + tmpMethod.action
    return this.state.methods_allow[methodName];
  }
  
  handleChange = event => {
    const id = event.target.type === 'checkbox' ? event.target.name : event.target.id;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      [id]: value
    });
  }

  handleMethodsChange = event => {
    const tmpMethod = this.state.methods[event.target.name.split("_")[1]]
    const methodName = tmpMethod.api_name + '|' + tmpMethod.version + '|' + tmpMethod.category + '|' + tmpMethod.action

    var tmp_methods_allow = this.state.methods_allow;

    switch (event.target.value) {
      case "allow":
        tmp_methods_allow[methodName] = true;
        break;
      case "deny":
        tmp_methods_allow[methodName] = false;
        break;
      default:
        delete tmp_methods_allow[methodName];
        break;
    }

    this.setState({
      methods_allow: tmp_methods_allow
    });
  }
  
  handleSubmit = async event => {
    event.preventDefault();
  
    this.setState({ isLoading: true });
  
    try {
      await this.saveRole({
        role_name: this.state.role_name,
        description: this.state.description,
        default_role: this.state.default_role,
        methods_allow: this.state.methods_allow
      });
      this.props.history.push("/roles");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  saveRole(role) {
    return API.put("tenant", `/role/${this.props.match.params.role_name}`, {
      body: role
    });
  }

  handleDelete = async event => {
    event.preventDefault();
  
    const confirmed = window.confirm(
      "Are you sure you want to delete this role?"
    );
  
    if (!confirmed) {
      return;
    }
  
    this.setState({ isDeleting: true });

    try {
      await this.deleteRole();
      this.props.history.push("/roles");
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  }
  
  deleteRole() {
    return API.del("tenant", `/role/${this.props.match.params.role_name}`);
  }

  strCapitalize(string_val) {
    return string_val.charAt(0).toUpperCase() + string_val.slice(1);
  }

  renderMethodsList(methods) {
    return methods.map((method, i) =>
      <tr key={`${"methodEndpointRowId_" + i}`}>
        <td>{`${this.strCapitalize(method.api_name) + ' - ' + method.version + ' | ' + method.category + ' - ' + method.action}`}</td>
        <td align="center">
          <Radio
            name={`${"methodEndpointId_" + i}`}
            value={"allow"}
            onChange={this.handleMethodsChange}
            checked={this.methodState(i) === true}
          />
        </td>
        <td align="center">
          <Radio
            name={`${"methodEndpointId_" + i}`}
            value="deny"
            onChange={this.handleMethodsChange}
            checked={this.methodState(i) === false}
          />
        </td>
        <td align="center">
          <Radio
            name={`${"methodEndpointId_" + i}`}
            value="na"
            onChange={this.handleMethodsChange}
            checked={this.methodState(i) === undefined}
          />
        </td>
      </tr>
    );
  }

  render() {
    return (
      <div className="Role">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="role_name">
            <ControlLabel>Role Name</ControlLabel>
            <FormControl
              autoFocus
              disabled={true}
              onChange={this.handleChange}
              value={this.state.role_name}
              type="text"
            />
          </FormGroup>
          <FormGroup controlId="description">
            <ControlLabel>Description</ControlLabel>
            <FormControl
              autoFocus
              onChange={this.handleChange}
              value={this.state.description}
              type="text"
            />
          </FormGroup>
          <FormGroup controlId="default_role">
            <ControlLabel>Default Role</ControlLabel>
            <Checkbox
              name="default_role"
              onChange={this.handleChange}
              defaultChecked={this.state.default_role}
            />
          </FormGroup>
          <FormGroup controlId="methods_allow">
            <ControlLabel>Methods</ControlLabel>
              <Table striped bordered condensed hover>
                <thead>
                  <tr>
                    <th></th>
                    <th>Allow</th>
                    <th>Deny</th>
                    <th>N/A</th>
                  </tr>
                </thead>
                <tbody>
                  {!this.state.isLoading && this.renderMethodsList(this.state.methods)}
                </tbody>
              </Table>
          </FormGroup>
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Save"
            loadingText="Saving…"
          />
          <LoaderButton
            block
            bsStyle="danger"
            bsSize="large"
            isLoading={this.state.isDeleting}
            onClick={this.handleDelete}
            text="Delete"
            loadingText="Deleting…"
          />
        </form>
      </div>
    );
  }
}