import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel, Checkbox } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { API } from "aws-amplify";
import "./style.scss";

export default class Method extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: null,
      isDeleting: null,
      id: "",
      api_name: "",
      version: "",
      category: "",
      action: "",
      verb: "",
      resource: "",
      conditions: [],
      internal_only: null
    };
  }

  async componentDidMount() {
    try {
      const res = await this.getMethod();
      const { api_name, version, category, action, verb, resource, conditions, internal_only } = res;

      this.setState({
        api_name,
        version,
        category,
        action,
        verb,
        resource,
        conditions,
        internal_only
      });
    } catch (e) {
      alert(e);
    }
  }

  getMethod() {
    return API.get("tenant", `/method/${this.props.match.params.api_name}/${this.props.match.params.version}/${this.props.match.params.category}/${this.props.match.params.action}`);
  }
  
  validateForm() {
    return this.state.api_name.length > 0 &&
    this.state.version.length > 0 &&
    this.state.category.length > 0 &&
    this.state.action.value !== "select" &&
    this.state.verb.value !== "select";
  }

  handlConditionsChange = event => {
    this.setState({
      [event.target.id]: event.target.value.split('\n')
    });
  }
  
  handleChange = event => {
    const id = event.target.type === 'checkbox' ? event.target.name : event.target.id;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      [id]: value
    });
  }
  
  handleSubmit = async event => {
    event.preventDefault();
  
    this.setState({ isLoading: true });
  
    try {
      await this.saveMethod({
        api_name: this.state.api_name,
        version: this.state.version,
        category: this.state.category,
        action: this.state.action,
        verb: this.state.verb,
        resource: this.state.resource,
        conditions: this.state.conditions,
        internal_only: this.state.internal_only
      });
      this.props.history.push("/methods");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  saveMethod(method) {
    return API.put("tenant", `/method/${this.props.match.params.api_name}/${this.props.match.params.version}/${this.props.match.params.category}/${this.props.match.params.action}`, {
      body: method
    });
  }

  handleDelete = async event => {
    event.preventDefault();
  
    const confirmed = window.confirm(
      "Are you sure you want to delete this method?"
    );
  
    if (!confirmed) {
      return;
    }
  
    this.setState({ isDeleting: true });

    try {
      await this.deleteMethod();
      this.props.history.push("/methods");
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  }
  
  deleteMethod() {
    return API.del("tenant", `/method/${this.props.match.params.api_name}/${this.props.match.params.version}/${this.props.match.params.category}/${this.props.match.params.action}`);
  }

  render() {
    return (
      <div className="Method">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="api_name">
            <ControlLabel>API Hostname</ControlLabel>
            <FormControl
              autoFocus
              onChange={this.handleChange}
              value={this.state.api_name}
              type="text"
            />
          </FormGroup>
          <FormGroup controlId="version">
            <ControlLabel>API Version</ControlLabel>
            <FormControl
              autoFocus
              onChange={this.handleChange}
              value={this.state.version}
              type="text"
            />
          </FormGroup>
          <FormGroup controlId="category">
            <ControlLabel>Category</ControlLabel>
            <FormControl
              autoFocus
              onChange={this.handleChange}
              value={this.state.category}
              type="text"
            />
          </FormGroup>
          <FormGroup controlId="action">
            <ControlLabel>Action</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.action}
              componentClass="select">
              <option value="select">Select</option>
              <option value="List">List</option>
              <option value="Create">Create</option>
              <option value="Read">Read</option>
              <option value="Update">Update</option>
              <option value="Delete">Delete</option>
            </FormControl>
          </FormGroup>
          <FormGroup controlId="verb">
            <ControlLabel>HTTP Verb</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.verb}
              componentClass="select">
              <option value="select">Select</option>
              <option value="POST">POST</option>
              <option value="GET">GET</option>
              <option value="PUT">PUT</option>
              <option value="PATCH">PATCH</option>
              <option value="DELETE">DELETE</option>
            </FormControl>
          </FormGroup>
          <FormGroup controlId="resource">
            <ControlLabel>Resource</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.resource}
              type="text"
            />
          </FormGroup>
          <FormGroup controlId="conditions">
            <ControlLabel>Conditions (one per line)</ControlLabel>
            <FormControl
              onChange={this.handlConditionsChange}
              value={this.state.conditions.join('\n')}
              componentClass="textarea"
            />
          </FormGroup>
          <FormGroup controlId="internal_only">
            <ControlLabel>Internal ONLY!</ControlLabel>
            <Checkbox
              name="internal_only"
              onChange={this.handleChange}
              defaultChecked={this.state.internal_only}
            />
          </FormGroup>
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Save"
            loadingText="Saving…"
          />
          <LoaderButton
            block
            bsStyle="danger"
            bsSize="large"
            isLoading={this.state.isDeleting}
            onClick={this.handleDelete}
            text="Delete"
            loadingText="Deleting…"
          />
        </form>
      </div>
    );
  }
}