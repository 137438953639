import React, { Component } from "react";
import { API } from "aws-amplify";

// import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import { FormGroup, FormControl } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
// import config from "../config";
import "./style.scss";

export default class Tenant extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: null,
      isDeleting: null,
      tenant: null,
      tenant_id: "",
      active: true
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      const tenant = await this.getTenant();
      const { tenant_id, active } = tenant;

      this.setState({
        tenant,
        tenant_id,
        active
      });
    } catch (e) {
      alert(e);
    }
  }

  getTenant() {
    return API.get("tenant", `/tenant`);
  }

  validateForm() {
    return this.state.content.length > 0;
  }
  
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }
  
  handleSubmit = async event => {
    event.preventDefault();
  
    this.setState({ isLoading: true });
  }
  
  render() {
    return (
      <div className="Tenant">
        {this.state.tenant &&
          <form onSubmit={this.handleSubmit}>
            <FormGroup controlId="active">
              <FormControl
                onChange={this.handleChange}
                value={this.state.active}
                componentClass="checkbox"
              />
            </FormGroup>
            <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Save"
              loadingText="Saving…"
            />
            <LoaderButton
              block
              bsStyle="danger"
              bsSize="large"
              isLoading={this.state.isDeleting}
              onClick={this.handleDelete}
              text="Deactivate"
              loadingText="Deactivating…"
            />
          </form>}
      </div>
    );
  }
}