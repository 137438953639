import React, { Component } from "react";
import {
  HelpBlock,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import "./style.scss";
import { Auth } from "aws-amplify";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

export default class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      username: "",
      password: "",
      email: "",
      givenName: "",
      familyName: "",
      orgName: "",
      phoneNumber: "",
      address: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: ""
      },
      confirmPassword: "",
      confirmationCode: "",
      newUser: null
    };
  }

  validateForm() {
    return (
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    if(event.target.id === 'email') {
      this.setState({
        username: event.target.value.replace("@", "-at-")
      })
    }
  }
//  Qwertyuiop098
  handleSubmit = async event => {
    event.preventDefault();
  
    this.setState({ isLoading: true });
  
    try {
      const newUser = await Auth.signUp({
        username: this.state.username,
        password: this.state.password,
        attributes: {
          email: this.state.email,
          given_name: this.state.givenName,
          family_name: this.state.familyName,
          name: this.state.orgName,
          phone_number: this.state.phoneNumber,
          address: JSON.stringify(this.state.address)
        }
      });
      this.setState({
        newUser: newUser
      });
    } catch (e) {
      if (e.code === 'UsernameExistsException') {
        try {
          const tryAgain = await Auth.resendSignUp(this.state.username);
          alert("This email address has been previously registered.\nWe have resent the confirmation code.")
          this.setState({
            newUser: tryAgain
          });
        } catch (err) {
          if (err.code === 'InvalidParameterException' && err.message === 'User is already confirmed.') {
            alert("This email address has been previously registered and confirmed.\nNeed help accessing your account? Please click 'Login' and reset your password.")
            this.props.history.push("/");
          } else {
          alert(err.message)
          }
        }
      } else {
        alert(e.message);
      }
    }
    this.setState({ isLoading: false });
  }
  
  handleConfirmationSubmit = async event => {
    event.preventDefault();
  
    this.setState({ isLoading: true });
  
    try {
      await Auth.confirmSignUp(this.state.username, this.state.confirmationCode);
      await Auth.signIn(this.state.username, this.state.password);
  
      this.props.userHasAuthenticated(true);
      this.props.history.push("/");
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  }

  renderConfirmationForm() {
    return (
      <form onSubmit={this.handleConfirmationSubmit}>
        <FormGroup controlId="confirmationCode" bsSize="large">
          <ControlLabel>Confirmation Code</ControlLabel>
          <FormControl
            autoFocus
            type="tel"
            value={this.state.confirmationCode}
            onChange={this.handleChange}
          />
          <HelpBlock>Please check your email for the code.</HelpBlock>
        </FormGroup>
        <LoaderButton
          block
          bsSize="large"
          disabled={!this.validateConfirmationForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Verify"
          loadingText="Verifying…"
        />
      </form>
    );
  }

  renderForm() {
    return (
      <form onSubmit={this.handleSubmit}>
        <FormGroup controlId="email" bsSize="large">
          <ControlLabel>Email</ControlLabel>
          <FormControl
            autoFocus
            type="email"
            value={this.state.email}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="givenName" bsSize="large">
          <ControlLabel>First Name</ControlLabel>
          <FormControl
            autoFocus
            type="text"
            value={this.state.givenName}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="familyName" bsSize="large">
          <ControlLabel>Last Name</ControlLabel>
          <FormControl
            autoFocus
            type="text"
            value={this.state.familyName}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="orgName" bsSize="large">
          <ControlLabel>Organization Name</ControlLabel>
          <FormControl
            autoFocus
            type="text"
            value={this.state.orgName}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="phoneNumber" bsSize="large">
          <ControlLabel>Phone Number</ControlLabel>
          <PhoneInput
            country="US"
            placeholder="Enter phone number"
            value={ this.state.phoneNumber }
            onChange={ phoneNumber => this.setState({ phoneNumber }) }
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <ControlLabel>Password</ControlLabel>
          <FormControl
            value={this.state.password}
            onChange={this.handleChange}
            type="password"
          />
        </FormGroup>
        <FormGroup controlId="confirmPassword" bsSize="large">
          <ControlLabel>Confirm Password</ControlLabel>
          <FormControl
            value={this.state.confirmPassword}
            onChange={this.handleChange}
            type="password"
          />
        </FormGroup>
        <LoaderButton
          block
          bsSize="large"
          disabled={!this.validateForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Signup"
          loadingText="Signing up…"
        />
      </form>
    );
  }

  render() {
    return (
      <div className="Signup">
        {this.state.newUser === null
          ? this.renderForm()
          : this.renderConfirmationForm()}
      </div>
    );
  }
}