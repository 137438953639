import React, { Component } from "react";
import "./style.scss";

export default class Home extends Component {
  render() {
    return (
      <div className="Home">
        <div className="lander">
          <h1>Concerted Technologies</h1>
          <p>Data Movement Simplified</p>
        </div>
      </div>
    );
  }
}