import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { Auth } from "aws-amplify";
import "./style.scss";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      challengeName: null,
      user: null,
      tenant_id: "",
      username: "",
      email: "",
      password: "",
      newPassword: "",
      confirmPassword: ""
    };
  }

  validateForm() {
    return this.state.tenant_id.length > 0 && this.state.username.length > 0 && this.state.password.length > 0;
  }

  validateNewPasswordForm() {
    return this.state.newPassword.length > 0 && this.state.newPassword === this.state.confirmPassword;
  }

  handleChange = event => {
    this.setState({[event.target.id]: event.target.value});
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });
    // BW*QMjCuS5Qc
    try {
      const user = await Auth.signIn(
        this.state.tenant_id + '||' + this.state.username,
        this.state.password);
      this.setState({ user: user })
      this.setState({ challengeName: user.challengeName })

      if (this.state.challengeName === null || this.state.challengeName === undefined) {
        this.props.userHasAuthenticated(true);
      }
    } catch (e) {
      if (e.code === 'UserNotConfirmedException') {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
      } else if (e.code === 'PasswordResetRequiredException') {
          // The error happens when the password is reset in the Cognito console
          // In this case you need to call forgotPassword to reset the password
          // Please check the Forgot Password part.
      } else {
          console.log(e);
      }
      alert(e.message);
    }
    this.setState({ isLoading: false });
  }

  handleNewPasswordSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });
    // BW*QMjCuS5Qc
    try {
      const loggedUser = await Auth.completeNewPassword(
          this.state.user,               // the Cognito User Object
          this.state.confirmPassword,       // the new password
      );
      this.setState({ user: loggedUser })
      this.setState({ challengeName: null })

      if (this.state.challengeName === null) {
        this.props.userHasAuthenticated(true);
      }
    } catch (e) {
      console.log(e);
      alert(e.message);
    }
    this.setState({ isLoading: false });
  }

  loginForm() {
    return (
      <form onSubmit={this.handleSubmit}>
        <FormGroup controlId="tenant_id" bsSize="large">
          <ControlLabel>Account ID</ControlLabel>
          <FormControl
            autoFocus
            type="text"
            value={this.state.tenant_id}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="username" bsSize="large">
          <ControlLabel>Username</ControlLabel>
          <FormControl
            autoFocus
            type="username"
            value={this.state.username}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <ControlLabel>Password</ControlLabel>
          <FormControl
            value={this.state.password}
            onChange={this.handleChange}
            type="password"
          />
        </FormGroup>
        <Link to="/login/reset">Forgot password?</Link>
        <LoaderButton
          block
          bsSize="large"
          disabled={!this.validateForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Login"
          loadingText="Logging in…"
        />
        <Link to="/tenant_login">Tenant Owner? Login here...</Link>
      </form>
    );
  }

  newPasswordForm() {
    return (
      <form onSubmit={this.handleNewPasswordSubmit}>
        <div>Your password has expired!</div>
        <FormGroup controlId="newPassword" bsSize="large">
          <ControlLabel>New Password</ControlLabel>
          <FormControl
            value={this.state.newPassword}
            onChange={this.handleChange}
            type="password"
          />
        </FormGroup>
        <FormGroup controlId="confirmPassword" bsSize="large">
          <ControlLabel>Confirm Password</ControlLabel>
          <FormControl
            value={this.state.confirmPassword}
            onChange={this.handleChange}
            type="password"
          />
        </FormGroup>
        <LoaderButton
          block
          bsSize="large"
          disabled={!this.validateNewPasswordForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Login"
          loadingText="Logging in…"
        />
      </form>
    );
  }

  mfaSetupForm() {
    // This happens when the MFA method is TOTP
    // The user needs to setup the TOTP before using it
    // More info please check the Enabling MFA part
    // Auth.setupTOTP(user);
    return (
      <div>This is the mfa setup form!</div>
    );
  }

  mfaValidationForm() {
    // You need to get the code from the UI inputs
    // and then trigger the following function with a button click
    // const code = this.getCodeFromUserInput();
    // If MFA is enabled, sign-in should be confirmed with the confirmation code
    // const loggedUser = await Auth.confirmSignIn(
    //     user,   // Return object from Auth.signIn()
    //     code,   // Confirmation code  
    //     user.challengeName // MFA Type e.g. SMS, TOTP.
    // );
    return (
      <div>This is the mfa setup form!</div>
    );
  }

  renderChallengeForm() {
    let form = null;

    switch(this.state.challengeName) {
      case 'NEW_PASSWORD_REQUIRED':
        form = this.newPasswordForm();
        break;
      case 'MFA_SETUP':
        form = this.mfaSetupForm();
        break;
      case 'SMS_MFA':
        form = this.mfaValidationForm();
        break;
      case 'SOFTWARE_TOKEN_MFA':
        form = this.mfaValidationForm();
        break;
      default:
        form = this.loginForm();
    }
    
    return form;
  }

  render() {
    return (
     <div className="Login">
        {this.renderChallengeForm()}
      </div>
    );
  }
}